import { OrbitControls } from "@react-three/drei";
import { GroupProps, useFrame, useLoader, useThree } from "@react-three/fiber";
import React, { Suspense, useContext, useMemo, useRef } from "react";
import { GLTFLoader } from "three-stdlib";
import modelUrl from "../../../assets/Temple.gltf";
import { AudioPlayerContext } from "../../../contexts";
import { calculateCustomAudioImpact } from "../../../utilities/utils";
import { AmbientLight } from "three";
import { colors } from "../../../utilities/constants";

export function TempleScene() {
  const { camera } = useThree();
  camera.position.setZ(40);
  camera.position.setY(5);

  return (
    <>
      <Suspense fallback={null}>
        <Temple position={[0, -15, 0]} />
      </Suspense>
      <color attach="background" args={["#000000"]} />
      <fog attach="fog" args={["#101010", 50, 75]} />
      <OrbitControls camera={camera} target={[0, -0, 0]} />
      <AmbientDiscoLight />
    </>
  );
}

const AmbientDiscoLight = () => {
  const audioPlayerContext = useContext(AudioPlayerContext);
  const ref = useRef<AmbientLight>();

  useFrame((state, delta) => {
    if (!audioPlayerContext.isPlaying) {
      return;
    }
    const audioImpact = calculateCustomAudioImpact(
      audioPlayerContext.analyser,
      1,
      colors.length,
    );
    const color = colors[Math.round(audioImpact - 1)];
    ref.current.color.set(color);
  });

  return <ambientLight ref={ref} intensity={1} color={"#ff0000"} />;
};

const Temple = (props: GroupProps) => {
  const { scene } = useLoader(GLTFLoader, modelUrl);

  return (
    <group {...props}>
      <primitive object={scene} />
    </group>
  );
};
