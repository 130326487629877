import React, { useContext, useRef } from "react";
import { useFrame, Vector3 } from "@react-three/fiber";
import { Box, OrbitControls } from "@react-three/drei";
import { AudioPlayerContext } from "../../../contexts";
import { calculateAudioImpact } from "../../../utilities/utils";

export function BoxScene() {
  return (
    <>
      <SimpleBox position={[-2, 0, 0]} />
      <SimpleBox position={[2, 0, 0]} />
      <color attach="background" args={["#000000"]} />
      <fog attach="fog" args={["#101010", 1, 25]} />
      <ambientLight intensity={0.015} />
      <OrbitControls />
    </>
  );
}

function SimpleBox({ position, color }: { position: Vector3; color?: string }) {
  const audioPlayerContext = useContext(AudioPlayerContext);
  const ref = useRef<THREE.Mesh>();

  useFrame((state, delta) => {
    if (!audioPlayerContext.isPlaying) {
      return;
    }
    const audioImpact = calculateAudioImpact(audioPlayerContext.analyser);
    ref.current.rotation.x += audioImpact * 0.01;
    ref.current.rotation.z += audioImpact * 0.01;
    ref.current.rotation.y += audioImpact * 0.01;
    ref.current.scale.setScalar(audioImpact);
  });

  return (
    <Box
      ref={ref}
      position={position}
      args={[1, 1, 1]}
      material-color={color ?? "orange"}
    />
  );
}
