// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.audio-player {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-areas: "audioplayer visualizertoggle" "visualizer visualizer";
  grid-template-rows: 54px auto;
  grid-template-columns: 1fr 1fr;
}
.audio-player .audio {
  grid-area: audioplayer;
  width: 100%;
  height: 100%;
}
.audio-player .visualizer-toggle {
  grid-area: visualizertoggle;
  background-color: aliceblue;
  color: aquamarine;
}
.audio-player .visualizer {
  grid-area: visualizer;
}
`, "",{"version":3,"sources":["webpack://./src/components/AudioPlayer/AudioPlayer.less"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,2EAAA;EAGA,6BAAA;EACA,8BAAA;AADF;AAPA;EAWI,sBAAA;EACA,WAAA;EACA,YAAA;AADJ;AAZA;EAiBI,2BAAA;EACA,2BAAA;EACA,iBAAA;AAFJ;AAjBA;EAuBI,qBAAA;AAHJ","sourcesContent":[".audio-player {\n  width: 100%;\n  height: 100%;\n  display: grid;\n  grid-template-areas:\n    \"audioplayer visualizertoggle\"\n    \"visualizer visualizer\";\n  grid-template-rows: 54px auto;\n  grid-template-columns: 1fr 1fr;\n\n  .audio {\n    grid-area: audioplayer;\n    width: 100%;\n    height: 100%;\n  }\n\n  .visualizer-toggle {\n    grid-area: visualizertoggle;\n    background-color: aliceblue;\n    color: aquamarine;\n  }\n\n  .visualizer {\n    grid-area: visualizer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
