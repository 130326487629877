// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.threejs-visualizer {
  top: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/ThreeJsVisualizer/ThreeJsVisualizer.less"],"names":[],"mappings":"AAAA;EACE,MAAA;AACF","sourcesContent":[".threejs-visualizer {\n  top: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
