/**
 * Calculates the ratio of `value` over `maxValue`.
 *
 * @param {number} value - The actual value for which the ratio needs to be calculated.
 * @param {number} maxValue - The maximum possible value against which `value` is compared.
 * @returns {number} The ratio between 0 and 1. Returns 1 if `value` is greater than or equal to `maxValue`.
 */
export function computeRatio(value: number, maxValue: number): number {
  return value >= maxValue ? 1 : value / maxValue;
}

/**
 * Scales a given ratio between the specified `min` and `max` bounds.
 *
 * @param {number} ratio - The ratio to be scaled, should be between 0 and 1.
 * @param {number} min - The minimum value of the scale.
 * @param {number} max - The maximum value of the scale.
 * @returns {number} The scaled ratio between `min` and `max`.
 */
export function scaleRatio(ratio: number, min: number, max: number): number {
  return min + ratio * (max - min);
}

/**
 * Computes the scaled ratio of a given value over its maximum possible value,
 * then scales the ratio between the provided `minScale` and `maxScale` bounds.
 *
 * @param {number} value - The actual value for which the ratio needs to be calculated.
 * @param {number} maxValue - The maximum possible value against which `value` is compared.
 * @param {number} minScale - The minimum value of the desired scale.
 * @param {number} maxScale - The maximum value of the desired scale.
 * @returns {number} The scaled ratio between `minScale` and `maxScale`.
 */
export function computeScaledRatio(
  value: number,
  maxValue: number,
  minScale: number,
  maxScale: number,
): number {
  return scaleRatio(computeRatio(value, maxValue), minScale, maxScale);
}

export function calculateAudioImpact(analyser: AnalyserNode): number {
  const fft = getFFT(analyser);
  const avg = calculateAverage(fft);
  return computeScaledRatio(avg, analyser.frequencyBinCount, 1, 2);
}

export function calculateCustomAudioImpact(
  analyser: AnalyserNode,
  min: number,
  max: number,
): number {
  const fft = getFFT(analyser);
  const avg = calculateAverage(fft);
  return computeScaledRatio(avg, analyser.frequencyBinCount, min, max);
}

export function getFFT(analyser: AnalyserNode): Uint8Array {
  const data = new Uint8Array(analyser.frequencyBinCount);
  analyser.getByteFrequencyData(data);
  return data;
}

export function calculateAverage(arr: Uint8Array): number {
  const sum = arr.reduce((a, b) => a + b, 0);
  const avg = sum / arr.length || 0;
  return avg;
}
