import { AudioPlayerContext } from "../../../contexts";
import { colors } from "../../../utilities/constants";
import { calculateAudioImpact } from "../../../utilities/utils";
import { Box, OrbitControls } from "@react-three/drei";
import { Vector3, useFrame } from "@react-three/fiber";
import React, { useContext, useRef } from "react";
import { Vector3 as ThreeVector3 } from "three";
import { useLocalStorage } from "usehooks-ts";

export function BouncyBoxScene() {
  const [bouncyBoxCount, setBouncyBoxCount] = useLocalStorage(
    "bouncyBoxCount",
    40,
  );
  // eslint-disable-next-line no-console
  console.debug(
    `Going for ${bouncyBoxCount} boxes, this can be adjusted by setting the bouncyBoxCount setting in local storage.`,
  );
  return (
    <>
      {[...Array(bouncyBoxCount)].map((_, i) => {
        return (
          <BouncyBox
            position={[0, 0, 0]}
            key={`Box_${i}`}
            color={colors[i % colors.length]}
          />
        );
      })}
      <color attach="background" args={["#000000"]} />
      <fog attach="fog" args={["#101010", 1, 25]} />
      <ambientLight intensity={0.015} />
      <OrbitControls />
    </>
  );
}

function BouncyBox({ position, color }: { position: Vector3; color?: string }) {
  const audioPlayerContext = useContext(AudioPlayerContext);
  const ref = useRef<THREE.Mesh>();

  // Random initial direction
  const direction = useRef(
    new ThreeVector3(
      Math.random() - 0.5,
      Math.random() - 0.5,
      Math.random() - 0.5,
    )
      .normalize()
      .multiplyScalar(0.0001),
  );

  useFrame((state, delta) => {
    if (!audioPlayerContext.isPlaying) {
      return;
    }

    // Adjust these values based on your canvas/frame size and box dimensions
    const boundary = { min: -4, max: 4 };

    const audioImpact = calculateAudioImpact(audioPlayerContext.analyser);
    ref.current.rotation.x += audioImpact * 0.01;
    ref.current.rotation.z += audioImpact * 0.01;
    ref.current.rotation.y += audioImpact * 0.01;
    ref.current.scale.setScalar(audioImpact * 2);
    const movement = direction.current
      .clone()
      .multiplyScalar(audioImpact * 25 * (1 + delta));
    ref.current.position.add(movement);

    if (
      ref.current.position.x < boundary.min ||
      ref.current.position.x > boundary.max ||
      ref.current.position.y < boundary.min ||
      ref.current.position.y > boundary.max ||
      ref.current.position.z < boundary.min ||
      ref.current.position.z > boundary.max
    ) {
      // Reverse the direction when hitting a boundary
      direction.current.negate();
    }
  });

  return (
    <Box
      ref={ref}
      position={position}
      args={[0.1, 0.1, 0.1]}
      material-color={color ?? "orange"}
    />
  );
}
