export type Scene =
  | "BoxScene"
  | "BouncyBoxScene"
  | "SpeakerScene"
  | "TempleScene";
export const SCENES: Scene[] = [
  "BoxScene",
  "BouncyBoxScene",
  "SpeakerScene",
  "TempleScene",
];

export * from "./BouncyBoxScene";
export * from "./BoxScene";
export * from "./SpeakerScene";
export * from "./TempleScene";
