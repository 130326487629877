import React, { useContext, useRef } from "react";
import { PtsCanvas } from "react-pts-canvas";
import { Sound, Pt, Circle, CanvasSpace, CanvasForm, Line } from "pts";
import "./PtsVisualizer.less";
import { AudioPlayerContext } from "../../contexts";
import { computeScaledRatio } from "../../utilities/utils";
import { colors } from "../../utilities/constants";

type Animation = (space: CanvasSpace, form: CanvasForm, audio: Sound) => void;
const animations: Animation[] = [
  bouncingSquaresAnimation,
  followingCircleAnimation,
];

export default function PtsVisualizer() {
  const audioPlayerContext = useContext(AudioPlayerContext);
  const animationIndex = useRef<number>(0);

  const switchAnimation = () => {
    if (animationIndex.current < animations.length - 1) {
      animationIndex.current++;
    } else {
      animationIndex.current = 0;
    }
  };

  return (
    <PtsCanvas
      name="pts-visualizer"
      onAnimate={(space, form, time, ftime) => {
        if (audioPlayerContext.sound) {
          animations[animationIndex.current].call(
            PtsVisualizer,
            space,
            form,
            audioPlayerContext.sound,
          );
        }
      }}
      onAction={(space, form, type, px, py, evt) => {
        if (type === "up") {
          // Switch between animations.
          switchAnimation();
        }
      }}
      background="black"
    />
  );
}

function bouncingSquaresAnimation(
  space: CanvasSpace,
  form: CanvasForm,
  audio: Sound,
) {
  audio.freqDomainTo(space.size).map((t, i) => {
    form.fillOnly(colors[i % 5]).point(t, 2);
  });
}

function followingCircleAnimation(
  space: CanvasSpace,
  form: CanvasForm,
  audio: Sound,
) {
  // Using the current mouse pointer location as the center of the visualization
  const center = space.pointer;
  const baseRadius = Math.min(space.size.x, space.size.y) * 0.05;

  // Get frequency domain data from the audio. Each frequency bin is represented as a point in 2D space.
  const bins: number = 256;
  const freqs = audio.freqDomainTo([bins, 1]);

  // Only draw the first 3/4 of the frequencies, as the latter ones are almost never visible.
  const freqsToDraw = freqs.filter((f, i) => i < freqs.length * 0.75);

  // Calculate the angle increment for each frequency bin.
  // Given there are 2*PI radians in a circle and we're visualizing each frequency as a radial line,
  // we split the circle evenly according to the number of frequency bins.
  const angleIncrement = (Math.PI * 2) / freqsToDraw.length;

  freqsToDraw.forEach((f, i) => {
    // Determine the angle for the current frequency bin based on its index.
    // The first frequency has an angle of 0, the second has angle = angleIncrement, and so on.
    const angle = angleIncrement * i;

    // Calculate the line's length using the normalized magnitude of the current frequency.
    // The idea is to scale the magnitude of the frequency (which is normalized to a 0-1 range)
    // to a range between the base radius and the maximum allowed length.
    const maxLength = calculateDynamicMaxLength(center, angle, space);
    const length = f.y * (maxLength - baseRadius);
    const width = calculateDynamicLineWidth(length, maxLength);

    // Determine the end point of the line segment representing this frequency.
    // This is calculated using the angle and the calculated length. The 'true' flag indicates
    // that we're using the default coordinate system (i.e., y increases upwards).
    const end = new Pt(center).toAngle(angle, length, true);

    // Draw the line from the center to the calculated end point with a color from the palette.
    form.stroke(colors[i % 5], width).line([center, end]);
  });

  // Draw a filled circle in the center of the visualization.
  form.fill("#000").circle(Circle.fromCenter(center, baseRadius));
}

/**
 * Calculate the maximum length dynamically based on the distance from the mouse pointer to the nearest edge.
 */
function calculateDynamicMaxLength(
  center: Pt,
  angle: number,
  space: CanvasSpace,
): number {
  // Calculate the end point for a very long line in the given direction
  // (This will certainly go beyond the canvas)
  const farEndPoint = new Pt(center).toAngle(
    angle,
    space.size.magnitude(),
    true,
  );

  // Check where this line intersects with the canvas bounds
  const intersection = Line.intersectRect2D(
    [center, farEndPoint],
    space.innerBound,
  );

  // If there's no intersection, return a default value
  if (!intersection) return 0;

  // Return the distance from the center to the intersection as the maximum length
  return center.$subtract(intersection[0]).magnitude();
}

/**
 * Calculate the width dynamically based on the length relative to the maximum length of a line.
 */
function calculateDynamicLineWidth(length: number, maxLength: number): number {
  return computeScaledRatio(length, maxLength, 1, 5);
}
