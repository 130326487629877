import { Canvas } from "@react-three/fiber";
import React, { useState } from "react";
import {
  BouncyBoxScene,
  BoxScene,
  SCENES,
  Scene,
  SpeakerScene,
  TempleScene,
} from "./Scenes";
import "./ThreeJsVisualizer.less";

export default function ThreeJsVisualizer() {
  const [selectedScene, setSelectedScene] = useState<Scene>(
    SCENES[SCENES.length - 1],
  );

  const switchScene = () => {
    const index = SCENES.indexOf(selectedScene);
    if (index < SCENES.length - 1) {
      setSelectedScene(SCENES[index + 1]);
    } else {
      setSelectedScene(SCENES[0]);
    }
  };

  const renderScene = () => {
    switch (selectedScene) {
      case "BoxScene":
        return <BoxScene />;
      case "SpeakerScene":
        return <SpeakerScene />;
      case "TempleScene":
        return <TempleScene />;
      case "BouncyBoxScene":
      default:
        return <BouncyBoxScene />;
    }
  };

  return (
    <Canvas shadows className="visualizer" onDoubleClick={switchScene}>
      {renderScene()}
    </Canvas>
  );
}
