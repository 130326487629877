import React, { useState, useRef } from "react";
import { Sound } from "pts";
import "./AudioPlayer.less";
import PtsVisualizer from "../PtsVisualizer/PtsVisualizer";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import ThreeJsVisualizer from "../ThreeJsVisualizer/ThreeJsVisualizer";
import { AudioPlayerContext } from "../../contexts";

export default function AudioPlayer({ audioSource }: { audioSource: string }) {
  const audioRef = useRef<null | HTMLAudioElement>(null);
  const audio = useRef<Sound>(null);
  const audioContext = useRef<AudioContext>(new AudioContext());
  const audioAnalyser = useRef<AnalyserNode>(null);
  const [visualizer, setVisualizer] = useState<"pts" | "threejs">("threejs");
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const onLoad = async () => {
    // Create an AnalyserNode to analyze the audio
    audioAnalyser.current = audioContext.current.createAnalyser();
    audioAnalyser.current.fftSize = 256; // Adjust as needed

    // Create a MediaElementAudioSourceNode from the audio element
    const source = audioContext.current.createMediaElementSource(
      audioRef.current,
    );
    source.connect(audioAnalyser.current);
    audioAnalyser.current.connect(audioContext.current.destination);

    // Create a Sound object from Pts
    const sound = Sound.from(audioAnalyser.current, audioContext.current);

    audio.current = sound;
    audio.current.analyze();

    audioRef.current.play();
    setIsPlaying(true);
  };

  const handleVisualizerToggle = (
    _event: React.MouseEvent<HTMLElement>,
    value: "pts" | "threejs" | null,
  ): void => {
    if (value !== null) {
      setVisualizer(value);
    }
  };

  const renderVisualizerToggle = () => {
    return (
      <ToggleButtonGroup
        className="visualizer-toggle"
        color="primary"
        value={visualizer}
        onChange={handleVisualizerToggle}
        exclusive
        aria-label="visualizer-library"
        fullWidth={true}
      >
        <ToggleButton value="pts">Pts</ToggleButton>
        <ToggleButton value="threejs">ThreeJs</ToggleButton>
      </ToggleButtonGroup>
    );
  };

  return (
    <div className="audio-player">
      <audio
        className="audio"
        ref={audioRef}
        src={audioSource}
        onLoadStart={onLoad}
        onPlay={handlePlayPause}
        onPause={handlePlayPause}
        loop={true}
        controls
        controlsList="nodownload"
      />
      {renderVisualizerToggle()}
      <AudioPlayerContext.Provider
        value={{
          isPlaying: isPlaying,
          sound: audio.current,
          analyser: audioAnalyser.current,
          context: audioContext.current,
        }}
      >
        {visualizer === "pts" ? <PtsVisualizer /> : <ThreeJsVisualizer />}
      </AudioPlayerContext.Provider>
    </div>
  );
}
