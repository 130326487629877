import {
  Center,
  OrbitControls,
  Text3D,
  useFont,
  Effects,
} from "@react-three/drei";
import { GroupProps, useFrame, useLoader, useThree } from "@react-three/fiber";
import React, { Suspense, useContext, useMemo, useRef } from "react";
import { GLTFLoader, RenderPass, UnrealBloomPass } from "three-stdlib";
import fontUrl from "../../../assets/Inter_Bold.json";
import modelUrl from "../../../assets/Speaker.gltf";
import { AudioPlayerContext } from "../../../contexts";
import { calculateAudioImpact } from "../../../utilities/utils";
import { Vector2 } from "three";

export function SpeakerScene() {
  const state = useThree();

  const renderScene = new RenderPass(state.scene, state.camera);
  const bloomPass = new UnrealBloomPass(
    new Vector2(window.innerWidth, window.innerHeight),
    0.4, // Strength
    0.6, // Radius
    0.5, // Threshold
  );

  return (
    <>
      <OrbitControls
        enableZoom={false}
        enablePan={false}
        minPolarAngle={Math.PI / 2}
        maxPolarAngle={Math.PI / 2}
        maxAzimuthAngle={1}
        minAzimuthAngle={-1}
      />
      <directionalLight position={[5, 5, 5]} intensity={2} />
      <directionalLight position={[-5, 5, 5]} intensity={2} />
      <Suspense fallback={null}>
        <Speaker position={[-2, -1.5, 0]} scale={2} rotation={[0, 0.5, 0]} />
        <Speaker position={[2, -1.5, 0]} scale={2} rotation={[0, -0.5, 0]} />
        <Center position={[0, 2, 0]}>
          <Banner />
        </Center>
      </Suspense>
      <Effects passes={[renderScene, bloomPass]} />
    </>
  );
}

const Banner = () => {
  const font = useFont(fontUrl as unknown as string);

  return (
    <group>
      <Text3D
        curveSegments={32}
        bevelEnabled
        bevelSize={0.04}
        bevelThickness={0.1}
        height={0.5}
        lineHeight={0.5}
        letterSpacing={0}
        size={1}
        font={font.data}
        rotation={[0.2, 0, 0]}
      >
        {`Welcome`}
        <meshNormalMaterial />
      </Text3D>
    </group>
  );
};

const Speaker = (props: GroupProps) => {
  const { scene } = useLoader(GLTFLoader, modelUrl);
  const copiedScene = useMemo(() => scene.clone(), [scene]);

  const audioPlayerContext = useContext(AudioPlayerContext);
  const ref = useRef<THREE.Mesh>();

  useFrame((state, delta) => {
    if (!audioPlayerContext.isPlaying) {
      return;
    }
    const audioImpact = calculateAudioImpact(audioPlayerContext.analyser);
    ref.current.scale.setScalar(audioImpact);
  });

  return (
    <group {...props}>
      <primitive ref={ref} object={copiedScene} />
    </group>
  );
};
