import React, { useState, ChangeEvent } from "react";
import AudioPlayer from "./AudioPlayer/AudioPlayer";
import "./App.less";

export default function App() {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileSelect = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }

    setSelectedFile(URL.createObjectURL(file));
  };

  return (
    <div className="fancy-listening-app">
      {selectedFile ? (
        <AudioPlayer audioSource={selectedFile} />
      ) : (
        <>
          <input type="file" accept="audio/*" onChange={handleFileSelect} />
          <p>Select an audio file from your device to start listening.</p>
        </>
      )}
    </div>
  );
}
